import { Theme } from './index'
import { getEnv } from './helpers'
import rmLogo from './logos/rm.svg'
import whiteRmLogo from './logos/rm-white.svg'
import healthAppImage from './images/rm-health-app.png'
import qrAppStore from './images/rm-appstore-qr.png'
import qrGooglePlay from './images/rm-googleplay-qr.png'
import bannerDesktop from './images/rm-banner-desktop.png'
import bannerTablet from './images/rm-banner-tablet.png'
import bannerMobile from './images/rm-banner-mobile.png'
import { getStoreUrls, getUrlForEnv } from './helpers'
import { getBrandedModule } from './defaultModules'

const isProduction = getEnv() === 'prod'

const colors = {
  yellow: '#FFE500',
  darkYellow: '#EAD100',
  black: '#000000',
  ironGray: '#333333',
  metalGray: '#DAD8D9',
  aluminiumGray: '#EFEFEF',
  button: {
    color: '#FFE500',
    hover: '#EAD100',
    disabled: '#DDDDDD',
    error: '#F71C1C',
  },
  text: {
    body: '#000000',
    headings: '#000000',
    link: '#000000',
    linkHover: '#EAD100',
  },
  monochrome: {
    darkestGrey: '#282B2E',
    darkerGrey: '#5B5B5B',
    darkGrey: '#777777',
    mediumGrey: '#AAAAAA',
    lightGrey: '#DDDDDD',
    lighterGrey: '#F2F2F2',
    lightestGrey: '#F8F8F8',
  },
}

const smallIcon = {
  style: {
    fontSize: '3em',
  },
}

const primaryTile = {
  color: colors.black,
  backgroundColor: colors.aluminiumGray,
  hoverBackgroundColor: colors.monochrome.lightGrey,
  descriptionColor: colors.black,
}

const externalTile = {
  ...primaryTile,
  backgroundColor: colors.yellow,
  hoverBackgroundColor: colors.darkYellow,
}

const storeUrls = getStoreUrls('https://distributorportal.rmhoist.com/rmi/en')
const warrantyUrl = 'https://rm-ext-prod.launchpad.cfapps.eu20.hana.ondemand.com/site/fiori'

const themeConfig: Theme = {
  brand: 'rm',
  portalName: 'Distributor Portal',
  privacyPolicyLink: 'https://rmhoist.com/data-protection',
  cookiePolicyLink: 'https://rmhoist.com/data-protection',
  companyLink: 'https://rmhoist.com',
  siteTermsLink: '/terms-and-conditions/alpha-portal/latest.defaut.html',
  fontFamily: 'Montserrat',
  fontSize: '15px',
  headerLogo: rmLogo,
  menuLogo: rmLogo,
  footerLogo: whiteRmLogo,
  headerLogoSize: '150px',
  menuLogoSize: '150px',
  footerLogoSize: '100px',
  footerCopyrightId: 'rm_copyright',
  primary: colors.yellow,
  pageHeaderColor: colors.text.headings,
  headerBackgroundColor: colors.black,
  linkColor: colors.text.link,
  linkHoverColor: colors.text.linkHover,
  dashboardBanner: {
    desktopImage: bannerDesktop,
    tabletImage: bannerTablet,
    mobileImage: bannerMobile,
    title: 'rm_dashboard_title',
    subtitle: 'rm_dashboard_subtitle',
    borderColor: colors.aluminiumGray,
  },
  healthApp: {
    qrAppStore,
    qrGooglePlay,
    image: healthAppImage,
    appStoreUrl: 'https://apps.apple.com/us/app/r-m-oli/id1492428871',
    googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.rm.oli',
    moreInfoUrl: 'https://rmhoist.com/products/digital-products/overhead-lifting-information-oli',
  },
  header: {
    color: colors.text.headings,
    backgroundColor: colors.ironGray,
  },
  menuLink: {
    color: colors.black,
    hoverBackgroundColor: colors.monochrome.lighterGrey,
    hoverColor: colors.black,
  },
  listItem: {
    titleColor: colors.monochrome.darkestGrey,
    descriptionColor: colors.monochrome.darkGrey,
  },
  button: {
    primary: {
      color: colors.button.color,
      textColor: colors.text.body,
      hover: colors.button.hover,
    },
  },
  modules: [
    // Main modules below:
    getBrandedModule('activationFlow'),
    getBrandedModule('dashboard'),
    getBrandedModule('contact', {
      to: getUrlForEnv('https://distributorportal.rmhoist.com/rmi/en/directory'),
      title: 'contact_rm',
      menuItemTitle: 'contact_rm',
      external: true,
    }),
    getBrandedModule('businessDashboard', {
      upperCaseTitle: true,
      external: false,
      secondary: false,
      tileProps: externalTile,
      tileIconProps: {
        style: {
          fontSize: '5em',
        },
      },
    }),
    getBrandedModule('troubleshooting', {
      external: false,
      upperCaseTitle: true,
      secondary: false,
      tileProps: externalTile,
      tileIconProps: {
        style: {
          fontSize: '5em',
        },
      },
    }),
    getBrandedModule('store', {
      to: storeUrls.storeUrl,
      title: 'distributor_portal',
      upperCaseTitle: true,
      secondary: false,
      external: true,
      tileProps: externalTile,
      tileIconProps: {
        style: {
          fontSize: '5em',
        },
      },
    }),
    getBrandedModule('documents', {
      to: storeUrls.documentsUrl,
      upperCaseTitle: true,
      secondary: true,
      external: true,
      tileProps: externalTile,
      tileIconProps: {
        style: {
          fontSize: '3em',
        },
      },
    }),
    getBrandedModule('deliveryTracker', {
      to: storeUrls.deliveryTrackerUrl,
      upperCaseTitle: true,
      secondary: true,
      external: true,
      tileProps: externalTile,
    }),
    getBrandedModule('chainSystem', {
      title: 'saleschamp',
      secondary: true,
      tileProps: externalTile,
    }),
    getBrandedModule('eLearning', {
      secondary: true,
      upperCaseTitle: true,
      tileProps: externalTile,
    }),
    getBrandedModule('healthApp', {
      secondary: true,
      upperCaseTitle: true,
      title: 'oli',
      pageTitle: 'oli_title',
      tileProps: primaryTile,
    }),
    getBrandedModule('hotjarDecrypt', {
      secondary: true,
      tileProps: primaryTile,
    }),
    getBrandedModule('warranty', {
      to: warrantyUrl,
      description: 'place_warranty_notifications',
      upperCaseTitle: true,
      secondary: true,
      external: true,
      tileProps: primaryTile,
    }),
    ...(!isProduction
      ? [
          getBrandedModule('assetFleet', {
            secondary: true,
            tileProps: primaryTile,
          }),
          getBrandedModule('myPartnersAndEquipment', {
            // IIFT4-933
            title: 'equipment',
            description: 'swf_my_partners_and_equipment_description',
            secondary: true,
            tileProps: primaryTile,
            tileIconProps: {
              size: '3x',
            },
          }),
        ]
      : []),
    // Demo modules below:
    getBrandedModule('trainingCalendar', {
      upperCaseTitle: true,
      demo: true,
      secondary: true,
      tileProps: primaryTile,
      tileIconProps: smallIcon,
    }),
    ...(isProduction
      ? [
          getBrandedModule('remoteDataDemo', {
            demo: true,
            secondary: true,
            upperCaseTitle: true,
            tileProps: primaryTile,
            tileIconProps: smallIcon,
          }),
        ]
      : []),
    getBrandedModule('marketingBank', {
      upperCaseTitle: true,
      demo: true,
      secondary: true,
      tileProps: primaryTile,
      tileIconProps: smallIcon,
    }),
    ...(!isProduction
      ? [
        getBrandedModule('leadTimes', {
          upperCaseTitle: true,
          demo: true,
          secondary: true,
          tileProps: primaryTile,
          tileIconProps: smallIcon,
        }),
      ]
      : []),
    getBrandedModule('monitoredAsset'),
  ],
}

export function loadTheme(): void {
  loadFontAndIcon()
  setFontSize()
  updatePageTitle()
}

function loadFontAndIcon() {
  const head = document.getElementsByTagName('head')[0]
  const fontLinkEl = document.createElement('link')
  fontLinkEl.rel = 'stylesheet'
  fontLinkEl.type = 'text/css'
  fontLinkEl.href =
    'https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700&display=swap'
  head.appendChild(fontLinkEl)

  const iconLinkEl = document.createElement('link')
  iconLinkEl.rel = 'icon'
  iconLinkEl.type = 'image/x-icon'
  iconLinkEl.href = './icons/rm.ico'
  head.insertBefore(iconLinkEl, head.firstChild)
}

function setFontSize() {
  document.documentElement.style.fontSize = themeConfig.fontSize!
}

function updatePageTitle() {
  const title = document.getElementsByTagName('title')[0]

  if (title) title.textContent = themeConfig.portalName!
}

export default themeConfig
